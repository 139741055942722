import React, { Component } from "react";

import notFoundImg from "../../../../../assets/img/browser.png";
import LoaderData from "../../../../../loaders/loader_01";

import Propertie from "../../../../../services/propertie";
import PropertieType from "../../../../../services/propertieType";
import PropertieItem from "../../../../../components/properties/propertieItem";

class SearchByPropertyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: [],
      propertieListe: [],
      propertieTypeListe: [],
      propertieTypeId: 0,
      item: [],
      pageTitle: "",
      fetchindData: false,
    };
  }

  getPropertieType() {
    this.setState({ fetchindData: true });
    PropertieType.getPropertie_typeList().then((result) => {
      this.setState({ propertieTypeListe: result.data });
      for (let i = 0; i < result.data.length; i++) {
        if (
          this.props.match.params.type.toLowerCase() ==
          result.data[i].title.toLowerCase()
        ) {
          let item = { ...this.state.item };
          let array = [result.data[i].id];
          item["propertieTypeId"] = array;
          this.setState({ item });
          this.setState({ pageTitle: result.data[i].title });
          this.SearchformEngine(item);
        }
      }
    });
  }

  SearchformEngine = (searchParams) => {
    Propertie.PropertieSearchEngine(searchParams).then((result) => {
      this.setState({ propertieListe: result.data });
      setTimeout(() => {
        this.setState({ fetchindData: false });
      }, 2000);
    });
  };

  componentDidMount() {
    this.getPropertieType();
  }

  render() {
    const { propertieListe, fetchindData } = this.state;
    return (
      <>
        <div className="search-form--box">
          <div className="container">
            {/* <Searchform /> */}
            <h2 className="section-title">
              Annonces immobilières répertoriées dans{" "}
              {this.props.match.params.type}
            </h2>
          </div>
        </div>
        <div className="container">
          {fetchindData ? (
            <div className="text-center content-loading">
              <LoaderData />
            </div>
          ) : (
            <div className="kylomo-section section-standard">
              {propertieListe.length > 0 ? (
                <div className="row">
                  {propertieListe.map((item, index) => (
                    <div className="col-md-4">
                      <PropertieItem item={item} key={index} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="not-found-box text-center">
                  <img src={notFoundImg} alt="" />
                  <p className="mt-3">
                    Aucun resultat trouvé pour votre recherche
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SearchByPropertyType;
