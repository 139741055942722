import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledCollapse,
  Modal,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from 'reactstrap'

import avatar from '../../../../../assets/img/sbcf-default-avatar.png'

const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUsername = currentUser?.username

class SideBarLeft extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  logOut() {
    localStorage.clear()
    window.location.href = '/'
  }

  render() {
    return (
      <div className="side-bar-left">
        <div className="current-user-box">
          <img src={avatar} alt="" />
          <p>Bienvenue {currentUsername} </p>
        </div>
        <ul className="main-menu">
          <li>
            <NavLink to="/agent/dashboard">
              <i className="nc-icon nc-layout-11"></i> Tableau de bord
            </NavLink>
          </li>
          <li>
            <NavLink to="/agent/mon-profil">
              <i className="nc-icon nc-circle-10"></i> Mon profil
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/agent/annonces">
              <i className="nc-icon nc-paper"></i> Mes annonces
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/agent/annonces" id="toggler">
              <i className="nc-icon nc-paper"></i> Mes annonces
            </NavLink>
          </li>
          <li>
            <UncontrolledCollapse toggler="#toggler">
              <ul>
                <li>
                  <Link to="/agent/annonces">Tout</Link>
                </li>
                <li>
                  <Link to="/agent/annonces/publish">Publiée</Link>
                </li>
                <li>
                  <Link to="/agent/annonces/disabled">Désactivée</Link>
                </li>
                <li>
                  <Link to="/agent/annonces/expired">Expirée</Link>
                </li>
                <li>
                  <Link to="/agent/annonces/unpublish">
                    En attente d'approbation
                  </Link>
                </li>
              </ul>
            </UncontrolledCollapse>
          </li>
          <li>
            <NavLink to="/agent/annonce/add">
              <i className="nc-icon nc-simple-add"></i> Publier une nouvelle
              annonce
            </NavLink>
          </li>
          <li>
            <NavLink to="/logout" onClick={this.logOut}>
              <i className="nc-icon nc-button-power"></i> Se déconnecter
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default SideBarLeft
