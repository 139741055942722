import React from 'react'
import parse from 'html-react-parser'

const body = (props) => {
  const convertToPlain = (html) => {
    // Create a new div element
    var tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || ''
  }
  const bodyBrute = String(props.propertieData.body)
  // const body = bodyBrute.replace(/<[^>]+>/g, "");
  // const body = props.propertieData.body;
  const body = parse(bodyBrute)
  console.log(bodyBrute)
  console.log(body)
  return (
    <>
      <div
        className="property_description b-r-20"
        id="property_description_section"
      >
        <h4 className="panel-title">Description</h4>
        <div> {parse(bodyBrute)} </div>
      </div>
      <div className="panel-group property-panel b-r-20">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 className="panel-title">Address</h4>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="listing_detail col-md-4">
                <strong>Pays:</strong> Côte d'Ivoire
              </div>
              <div className="listing_detail col-md-4">
                <strong>Zone:</strong>{' '}
                <a href="http://kylimo:8888/city/cocdy/" rel="tag">
                  Abidjan
                </a>
              </div>
              <div className="listing_detail col-md-4">
                <strong>Ville:</strong>{' '}
                <a href="http://kylimo:8888/city/cocdy/" rel="tag">
                  Cocody
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default body
