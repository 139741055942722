import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AnnonceResume from './Component/annonceResume'
import AnnonceItem from './Component/AnnonceItem'

// services import

import Propertie from '../../../../services/propertie'

const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUserId = currentUser?.id
const currentUsername = currentUser?.username
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propertieListe: [],
      PublishPropertieCount: 0,
      AllPropertieCount: 0,
      DisablePropertieCount: 0,
      PendindPropertieCount: 0,
      ExpiredPropertieCount: 0,
    }
  }

  getUserActivedProperties() {
    Propertie.getUserPublishPropertieList(currentUserId).then((result) => {
      this.setState({
        propertieListe: result.data,
        PublishPropertieCount: result.data.length,
      })
    })
  }

  getUserAllProperties() {
    Propertie.getAllUserPropertieList(currentUserId).then((result) => {
      this.setState({
        AllPropertieCount: result.data.length,
      })
    })
  }

  getUserDisabledProperties() {
    Propertie.getDesabledUserPropertieList(currentUserId).then((result) => {
      this.setState({
        DisablePropertieCount: result.data.length,
      })
    })
  }

  getUserUnPublishProperties() {
    Propertie.getUnpubishUserPropertieList(currentUserId).then((result) => {
      this.setState({
        PendindPropertieCount: result.data.length,
      })
    })
  }
  getUserExpiredProperties() {
    Propertie.getUserExpiredPropertieList(currentUserId).then((result) => {
      this.setState({
        ExpiredPropertieCount: result.data.length,
      })
    })
  }

  EditPropertieStatus = (propertieId, statusId) => {
    let data = {
      propertieStatusId: 2,
    }

    Propertie.updatePropertie(propertieId, data).then(() =>
      this.componentDidMount(),
    )
  }

  componentDidMount() {
    this.getUserActivedProperties()
    this.getUserAllProperties()
    this.getUserDisabledProperties()
    this.getUserUnPublishProperties()
    this.getUserExpiredProperties()
  }

  render() {
    const {
      propertieListe,
      PublishPropertieCount,
      AllPropertieCount,
      DisablePropertieCount,
      PendindPropertieCount,
      ExpiredPropertieCount,
    } = this.state
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Tableau de bord</h2>
        </div>
        <div className="dsh-content">
          <AnnonceResume
            PublishPropertieCount={PublishPropertieCount}
            AllPropertieCount={AllPropertieCount}
            DisablePropertieCount={DisablePropertieCount}
            PendindPropertieCount={PendindPropertieCount}
            ExpiredPropertieCount={ExpiredPropertieCount}
          />
          <div className="card user-active-properties-box">
            <h2 className="mb-4">
              <strong>Propriétés publiée ({propertieListe.length}) </strong>{' '}
            </h2>
            <div className="dashboard_table_list_header mb-3 row">
              <div className="col-md-4">Propriété</div>
              <div className="col-md-2">Catégorie</div>
              <div className="col-md-2">Statut</div>
              <div className="col-md-2">Prix </div>
              <div className="col-md-2">Actions</div>
            </div>
            {propertieListe.map((item, index) => (
              <AnnonceItem
                key={index}
                item={item}
                EditPropertieStatus={this.EditPropertieStatus}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
