import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// reactstrap components
// import { Container } from 'reactstrap'

import adminRoutes from '../../../adminRoutes'
import Dashboard from './Dashboard/Dashboard'
// core components
import Navbar from '../../../components/Navbars/ExamplesNavbar'
import SideBarLeft from './Dashboard/Component/SideBarLeft'
import DemoFooter from '../../../components/Footers/DemoFooter'

class Admin_views extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  cleanup() {
    document.body.classList.remove('profile-page')
  }

  componentDidMount() {
    document.documentElement.classList.remove('nav-open')
    document.body.classList.add('profile-page')
    this.cleanup()
  }

  render() {
    const menu = adminRoutes.map((route, index) => {
      console.log(route)
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null
    })

    return (
      <>
        <Navbar />
        <div className="main bg--gray admin-dashboard">
          <div className="row">
            <div className="col-md-2">
              <SideBarLeft />
            </div>
            <div className="col-md-10">
              <Switch>
                {menu}
                <Route exact path="/admin" component={Dashboard} />
              </Switch>
            </div>
          </div>
        </div>
        <DemoFooter />
      </>
    )
  }
}

export default Admin_views
