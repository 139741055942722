const titleTransformer = (title) => {
  const transformToLowercase = title.toLowerCase()

  const replaceSpaceByDash = transformToLowercase
    .replace(/\s/g, '-')
    .replace(/\(/g, '-')
    .replace(/\)/g, '-')
    .replace(/à/g, 'a')
    .replace(/á/g, 'a')
    .replace(/â/g, 'a')
    .replace(/é/g, 'e')
    .replace(/è/g, 'e')
    .replace(/ê/g, 'e')
    .replace(/ë/g, 'e')
    .replace(/î/g, 'i')
    .replace(/ï/g, 'i')
    .replace(/û/g, 'u')
    .replace(/ü/g, 'u')
    .replace(/ù/g, 'u')

  return replaceSpaceByDash
}

export default titleTransformer
