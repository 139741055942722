import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div className="not-found text-center">
        <h1>404</h1>

        <p>OOPS! PAGE INTROUVABLE</p>
        <Link to="/" className="btn-round bg-yellow m-0">
          Retourner à l'accueil
        </Link>
      </div>
    );
  }
}

export default NotFound;
