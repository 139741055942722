import AnonymousAddPropertie from './views/pages/AnonymousAddPropertie/AnonymousAddPropertie'
import SearchPropertieList from './views/pages/all_views/SearchPropertieList/SearchPropertieList'
import SearchByPropertyType from './views/pages/all_views/SearchPropertieList/SearchByFields/SearchByPropertyType'
import SearchByPropertyZone from './views/pages/all_views/SearchPropertieList/SearchByFields/SearchByPropertyZone'
import SearchByPropertyCity from './views/pages/all_views/SearchPropertieList/SearchByFields/SearchByPropertyCity'
import PropertieSinglePage from './views/pages/all_views/PropertieSinglePage/PropertieSinglePage'
import AnnonceKylimoList from './views/pages/all_views/AnnonceKylimoList/AnnonceKylimoList'

import Dashboard from './views/pages/all_views/home/Dashboard'

const Routes = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/ajouter-une-propriete',
    exact: true,
    name: 'AnonymousAddPropertie',
    component: AnonymousAddPropertie,
  },
  {
    path: '/advanced-search/:searchData',
    exact: true,
    name: 'SearchPropertieList',
    component: SearchPropertieList,
  },
  {
    path: '/annonce/:link',
    exact: true,
    name: 'PropertieSinglePage',
    component: PropertieSinglePage,
  },
  {
    path: '/annonce/type/:type',
    exact: true,
    name: 'SearchByPropertyType',
    component: SearchByPropertyType,
  },
  {
    path: '/annonce/area/:type',
    exact: true,
    name: 'SearchByPropertyZone',
    component: SearchByPropertyZone,
  },
  {
    path: '/annonce/city/:type',
    exact: true,
    name: 'SearchByPropertyCity',
    component: SearchByPropertyCity,
  },
  {
    path: '/annonce-kylimo',
    exact: true,
    name: 'AnnonceKylimoList',
    component: AnnonceKylimoList,
  },
]

export default Routes
