/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Container } from 'reactstrap'

import Searchform from '../SearchForm/Searchform'

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            'url(' +
            require('assets/img/bg/pexels-adrien-olichon-5078015.jpg').default +
            ')',
        }}
      >
        <div className="filter" />
        <div className="content-left">
          <Container>
            <div className="title-brand">
              <h1>Bienvenue sur Kylimo</h1>
              <p>
                Grâce à nos vastes opportunités de marketing et à notre présence
                internationale, nous offrons une exposition maximale à nos
                annonces, en atteignant des acheteurs qualifiés.
              </p>
            </div>
            <Searchform />
          </Container>
        </div>
      </div>
    </>
  )
}

export default IndexHeader
