import axios from 'axios'

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from '../config'

export default {
  // Categorie services
  createCategorie: (data) => {
    return axios.post(`${config.URL_END_POINT}/propertie_projet/add`, data)
  },
  getCategorieList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie_projet/list`)
  },
  updateCategorie: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/propertie_projet/update/${id}`,
      data,
    )
  },

  deleteCategorie: (id) => {
    return axios.delete(`${config.URL_END_POINT}/propertie_projet/${id}`)
  },
}
