import axios from 'axios'

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from '../config'

export default {
  // Propertie_image services
  //   createPropertie_image: (data) => {
  //     return axios.post(`${config.URL_END_POINT}/propertie_image/add`, data);
  //   },
  createPropertie_image: (data) => {
    return axios({
      method: 'POST',
      url: `${config.URL_END_POINT}/propertie_image/add`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
      },
    })
  },
  getPropertie_imageList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie_image/list`)
  },
  updatePropertie_image: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/propertie_image/update/${id}`,
      data,
    )
  },

  deletePropertie_image: (id) => {
    return axios.delete(`${config.URL_END_POINT}/propertie_image/${id}`)
  },
}
