import React, { Component } from "react";
import PropTypes from "prop-types";

import notFoundImg from "../../../../assets/img/browser.png";

import Propertie from "../../../../services/propertie";
import PropertieItem from "../../../../components/properties/propertieItem";
import Searchform from "../../../../components/SearchForm/Searchform";

const decipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

class SearchPropertieList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: [],
      propertieListe: [],
    };
  }

  SearchformEngine = (searchParams) => {
    Propertie.PropertieSearchEngine(searchParams).then((result) => {
      this.setState({ propertieListe: result.data });
    });
  };

  componentDidMount() {
    //To decipher, you need to create a decipher and use it:
    const myDecipher = decipher("mySecretSalt");
    const stringData = myDecipher(this.props.match.params.searchData); // --> 'the secret string'
    this.setState({ searchParams: JSON.parse(stringData) });
    this.SearchformEngine(JSON.parse(stringData));
  }

  render() {
    const { propertieListe } = this.state;
    return (
      <>
        <div className="search-form--box">
          <div className="container">
            <Searchform />
          </div>
        </div>
        <div className="container">
          <div className="kylomo-section section-standard">
            <h2 className="section-title">
              Resultat de recherche ({propertieListe.length})
            </h2>
            {propertieListe.length > 0 ? (
              <div className="row">
                {propertieListe.map((item, index) => (
                  <div className="col-md-4">
                    <PropertieItem item={item} key={index} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="not-found-box text-center">
                <img src={notFoundImg} alt="" />
                <p className="mt-3">
                  Aucun resultat trouvé pour votre recherche
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

SearchPropertieList.propTypes = {};

export default SearchPropertieList;
