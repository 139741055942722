import React, { Component } from 'react'
import Loader_01 from '../../../../loaders/loader_01'
import account from '../../../../services/account'
const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUsername = currentUser?.username
class Profil extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: [],
      currentUser: [],
      ErrorMessage: '',
      Error: false,
      dataSending: false,
      type: 'password',
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    let currentUser = { ...this.state.currentUser }
    currentUser[name] = value
    this.setState({ currentUser })
  }

  handleUpdateUser = (event) => {
    event.preventDefault()
    this.setState({ pending: true })
    account
      .updateUserInfo(this.state.currentUser.id, this.state.currentUser)
      .then((res) => {
        const userInfo = JSON.stringify(res.data.data)
        localStorage.setItem('Mpspg', userInfo)
        this.setState({
          notification: 'Votre profil a été mis à jour avec succès',
        })
        setTimeout(() => {
          window.location.reload()
        }, 4000)
      })
  }

  componentDidMount() {
    if (currentUser) {
      this.setState({ currentUser: currentUser })
    }
  }

  render() {
    const { Error, dataSending, ErrorMessage, currentUser } = this.state
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Mon profil</h2>
        </div>
        <div className="dsh-content">
          <div
            className="alert-with-icon alert alert-danger fade show"
            role="alert"
          >
            <div className="container">
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-bell-55"></i> Il est important de
                  renseigner intégralement votre profil pour permettre à vos
                  client de facilement vous contacter
                </div>
              </div>
            </div>
          </div>
          <div className="card userinfo p-5 b-r-20">
            {' '}
            <div className="profile--item">
              <div className="profile-body">
                {Error && (
                  <p className="text-danger text-center">{ErrorMessage}.</p>
                )}

                <form onSubmit={this.handleUpdateUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Nom</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez votre nom"
                          name="last_name"
                          id="last_name"
                          onChange={this.handleChange}
                          defaultValue={currentUser.last_name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Prénom</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez votre prénom"
                          name="first_name"
                          id="first_name"
                          onChange={this.handleChange}
                          defaultValue={currentUser.first_name}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Entrez votre email"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                          defaultValue={currentUser.email}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Téléphone</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez votre contact"
                          name="contact"
                          id="contact"
                          onChange={this.handleChange}
                          defaultValue={currentUser.contact}
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="form-group mb-4 password">
                        <label>Mot de passe </label>
                        <input
                          type={this.state.type}
                          className="form-control"
                          placeholder="Entrez votre mot de passe"
                          name="password"
                          id="password"
                          onChange={this.handleChange}
                          required
                        />
                        {this.state.type == "text" && (
                          <i
                            className="fa fa-eye-slash"
                            onClick={this.HidePassword}
                          ></i>
                        )}
                        {this.state.type == "password" && (
                          <i
                            className="fa fa-eye"
                            onClick={this.showPassword}
                          ></i>
                        )}
                      </div>
                    </div>
                     */}
                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        {!dataSending && (
                          <button className="btn btn-round btn-full-blue-violent full-width">
                            S'inscrire
                          </button>
                        )}
                        {dataSending && (
                          <button
                            className="btn btn-round btn-full-blue-violent full-width"
                            disabled
                          >
                            <Loader_01 />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profil
