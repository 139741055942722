import React from 'react'
import { Link } from 'react-router-dom'
import millier from '../../../../../components/function/millier'

const noticeArea = (props) => {
  console.log(props.propertieData)
  return (
    <div className="notice_area col-md-12 ">
      <div className="single_property_labels">
        <div className="property_title_label">
          <Link to={'/annonce/type/' + props.propertieType.title} rel="tag">
            {props.propertieType.title}
          </Link>
        </div>
        <div className="property_title_label actioncat">
          <Link to={'/annonce/type/' + props.propertieProjet.title} rel="tag">
            {props.propertieProjet.title}
          </Link>
        </div>
      </div>

      <h1 className="entry-title entry-prop">{props.propertieData.title}</h1>

      <div className="price_area  text-right">
        <span className="price_label text-right">
          {props.propertieData.sufixe_prix}
        </span>{' '}
        <br />
        {millier(props.propertieData.prix)} Fcfa{' '}
        <span className="price_label">{props.propertieData.prefixe_prix}</span>
      </div>

      <div className="property_categs">
        <i className="nc-icon nc-pin-3"></i>
        {props.zone.title}, {props.villeCommune.title}
      </div>

      {/* <div className="prop_social">
        <div className="share_unit" style="display: none;">
          <a
            href="http://www.facebook.com/sharer.php?u=http://kylimo:8888/properties/sint-sed-delectus-laboriosam-asperiores/&amp;t=Sint+sed+delectus+laboriosam+asperiores"
            target="_blank"
            className="social_facebook"
          >
            Facebook
          </a>
          <a
            href="http://twitter.com/intent/tweet?text=Sint+sed+delectus+laboriosam+asperiores+http%3A%2F%2Fkylimo%3A8888%2Fproperties%2Fsint-sed-delectus-laboriosam-asperiores%2F"
            className="social_tweet"
            target="_blank"
          >
            Twitter
          </a>
          <a
            href="http://pinterest.com/pin/create/button/?url=http://kylimo:8888/properties/sint-sed-delectus-laboriosam-asperiores/&amp;media=http://kylimo:8888/wp-content/uploads/2021/05/lpdai-immobilier-opp8fc8xe4dni3y57tds9tg82nphzrsplmukfqnjig.jpeg&amp;description=Sint+sed+delectus+laboriosam+asperiores"
            target="_blank"
            className="social_pinterest"
          >
            Pinterest
          </a>
          <a
            href="http://api.whatsapp.com/send?text=Sint+sed+delectus+laboriosam+asperiores+http%3A%2F%2Fkylimo%3A8888%2Fproperties%2Fsint-sed-delectus-laboriosam-asperiores%2F"
            className="social_whatsup"
            target="_blank"
          >
            WhatsApp
          </a>

          <a
            href="mailto:email@email.com?subject=Sint+sed+delectus+laboriosam+asperiores&amp;body=http%3A%2F%2Fkylimo%3A8888%2Fproperties%2Fsint-sed-delectus-laboriosam-asperiores%2F"
            data-action="share email"
            className="social_email"
          >
            Adresse email
          </a>
        </div>
        <div
          className="title_share share_list single_property_action"
          data-original-title="partager cette page"
        >
          <i className="fas fa-share-alt"></i>Partager{" "}
        </div>

        <div
          id="add_favorites"
          className="title_share single_property_action isnotfavorite"
          data-postid="274"
          data-original-title="Ajouter aux favoris"
        >
          <i className="far fa-heart"></i>Favori{" "}
        </div>

        <div
          id="print_page"
          className="title_share single_property_action"
          data-propid="274"
          data-original-title="Imprimer "
        >
          <i className="fas fa-print"></i>Imprimer{" "}
        </div>
      </div>
     */}
    </div>
  )
}

export default noticeArea
