import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import sepMillier from '../../components/function/millier'

class propertieItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customBobdy: '',
    }
  }

  TextAbstract(text, length, last) {
    console.log('text', text)
    if (text == null) {
      return ''
    }
    if (text.length <= length) {
      return text
    }
    text = text.substring(0, length)
    last = text.lastIndexOf(' ')
    text = text.substring(0, last)
    return text + '...'
  }

  // TextAbstract(string, length) {
  //   if (string.length > length) return string.substring(0, length) + "...";
  //   else return string;
  // }

  componentDidMount() {
    console.log('object', this.props.item.body)

    if (this.props.item.body) {
      // const bodyBrute = String(this.props.item.body);
      // const string = parse(bodyBrute);
      // console.log(string.props.children);
      const html = this.props.item.body
        .replace(/<style([\s\S]*?)<\/style>/gi, '')
        .replace(/<script([\s\S]*?)<\/script>/gi, '')
        .replace(/<\/div>/gi, '\n')
        .replace(/<\/li>/gi, '\n')
        .replace(/<li>/gi, '  *  ')
        .replace(/<\/ul>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/<br\s*[\/]?>/gi, '\n')
        .replace(/<[^>]+>/gi, '')
      this.setState({
        customBobdy: html.slice(0, 50) + '...',
      })
    }
  }

  render() {
    const { customBobdy } = this.state
    return (
      <div>
        <div className="property_listing  property_card_default">
          <div className="property_listing_img_section">
            <div className="property_listing_img">
              <Link
                to={'/annonce/' + this.props.item.link_prefix}
                tabindex="-1"
              >
                {this.props.item.propertie_images.length >= 1 ? (
                  <img
                    src={this.props.item.propertie_images[0].link}
                    className="img-responsive"
                    alt=""
                  />
                ) : (
                  <img
                    src="http://kylimo:8888/wp-content/uploads/2021/04/810faa39-af4a-3831-9028-a4c7501e054e.png"
                    className="img-responsive"
                    alt=""
                  />
                )}
              </Link>
            </div>
            <div className="property_listing_img_header">
              {this.props.item.propertiePrivilegeId === 2 && (
                <div className="featured_div">Premium</div>
              )}

              <div className="propertie-type-wrapper">
                <Link
                  to={'/annonce/type/' + this.props.item.propertie_type?.title}
                  rel="tag"
                  tabindex="-1"
                >
                  {this.props.item.propertie_type?.title}
                </Link>
              </div>
              <div className="propertie-projet-wrapper">
                {this.props.item.propertie_projet?.title}
              </div>
            </div>
            <div className="property_listing_img_bottom">
              <div className="property_media">
                <i className="nc-icon nc-image"></i> 1
              </div>
              <div className="property_location_image">
                <i className="nc-icon nc-pin-3"></i>
                <Link
                  to={'/annonce/area/' + this.props.item.zone.title}
                  rel="tag"
                  tabindex="-1"
                >
                  {this.props.item.zone.title}
                </Link>
                ,{' '}
                <Link
                  to={'/annonce/city/' + this.props.item.ville_commune.title}
                  rel="tag"
                  tabindex="-1"
                >
                  {this.props.item.ville_commune.title}
                </Link>
              </div>
            </div>
            <div className="featured_gradient"></div>
          </div>
          <div className="property_content">
            <h4>
              <Link
                to={'/annonce/' + this.props.item.link_prefix}
                tabindex="-1"
              >
                {this.props.item.title.length >= 40
                  ? this.props.item.title.slice(0, 40) + '...'
                  : this.props.item.title}
              </Link>
            </h4>
            <div className="listing_unit_price_wrapper">
              <span className="price_label">
                {this.props.item.sufixe_prix}{' '}
              </span>
              {sepMillier(this.props.item.prix)} Fcfa{' '}
              <span className="price_label">
                {this.props.item.prefixe_prix}
              </span>
            </div>
            <div className="listing_details the_grid_view">
              {this.props.item.body != null && customBobdy}
              {/* <LinesEllipsis
                text={customBobdy}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

propertieItem.propTypes = {}

export default propertieItem
