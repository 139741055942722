import React, { Component } from "react";
import PropTypes from "prop-types";

class annonceResume extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {
      PublishPropertieCount,
      AllPropertieCount,
      DisablePropertieCount,
      PendindPropertieCount,
      ExpiredPropertieCount,
    } = this.props;
    return (
      <div className="card annonces-recap">
        <h2>Résumé du compte</h2>
        <ul>
          <li>
            Annonce(s) comprise(s): <strong>illimité</strong>{" "}
          </li>
          <li>
            Tous les annonces: <strong> {AllPropertieCount} </strong>{" "}
          </li>
          <li>
            Annonce(s) publié(s): <strong>{PublishPropertieCount}</strong>{" "}
          </li>

          <li>
            Annonces Désactivées: <strong> {DisablePropertieCount} </strong>{" "}
          </li>

          <li>
            Annonce(s) en attente de validation:{" "}
            <strong> {PendindPropertieCount} </strong>{" "}
          </li>

          <li>
            Annonce(s) expirée(s): <strong> {ExpiredPropertieCount} </strong>{" "}
          </li>
        </ul>
      </div>
    );
  }
}

annonceResume.propTypes = {};

export default annonceResume;
