import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Modal,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Container,
  Button,
} from 'reactstrap'
import classnames from 'classnames'
import AnnonceSubscriptionFrom from './AnnonceSubscriptionFrom'

import logo from '../../../../assets/img/logo.png'

class AnnonceKylimoForm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    return (
      <div className="bg-gray">
        <Navbar
          className="bg-blue navbar navbar-expand-lg"
          color-on-scroll="300"
          expand="lg"
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand
                data-placement="bottom"
                to="/"
                title="KYLIMO"
                tag={Link}
              >
                KYLIMO
                {/* <img src={logo} /> */}
              </NavbarBrand>
            </div>
          </Container>
        </Navbar>
        {/* <div className="overlay">&nbsp;</div> */}
        <div className="annonce-card">
          <div className="annonce-text-section">
            <div className="annonce-desc">
              <h2>Confiez vos bien à Kylimo</h2>
              <p>
                Vous avez un bien immobilierque vous souhaitez vendre. Vous
                pouvez confier cette vente à l'agence Kylimo qui est
                propriétaire de cette plateforme. Elle dispose de cet fait d'une
                grande base de données de clients potentiels et de sa
                plate-forme pour communiquer efficacement pour la publicité de
                votre bien.<br></br>
                <br></br>En effet, une fois le contrat exclusif de vente à durée
                déterminée signé, votre bien disposera d'une place de choix sur
                la page d'accueil de notre plate-forme, dans le champ " Annonces
                KYLIMO ". <br></br>
                <br></br> Il aura de ce fait une bonne visibilité sur notre
                plate-forme et profitera également d'une communication gratuite
                dans les autres réseaux dont nous disposons.<br></br>
                <br></br>Si vous souhaitez effectivement nous confier la vente
                veuillez renseignez le formulaire ci-contre.<br></br>
                <br></br>
                Pour en savoir plus veuillez appeler au{' '}
              </p>
              <Link to="/signup/profil/customer">+225 07 07 00 00 00</Link>
            </div>
          </div>
          <div className="form-section">
            <AnnonceSubscriptionFrom />
          </div>
        </div>
      </div>
    )
  }
}

export default AnnonceKylimoForm
