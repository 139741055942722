import React, { Component } from 'react'
import propertie from '../../../../services/propertie'
import propertieType from '../../../../services/propertieType'

import IndexHeader from '../../../../components/Headers/IndexHeader'
import AnnonceKylimo from './Component/AnnonceKylimo'
import CallToAction from './Component/CallToAction'
import LastPublished from './Component/LastPublished'
import PropertiesLayout from './Component/PropertiesLayout'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propertiePremiumListe: [],
      propertieStandardListe: [],
      propertieTypeListe: [],
      slidesToShowNumber: 0,
    }
  }

  getPremiumProperties() {
    propertie.getPropertiePremiumList().then((result) => {
      this.setState({ propertiePremiumListe: result.data })
      if (result.data.length == 1) {
        this.setState({ slidesToShowNumber: 1 })
      }
      if (result.data.length == 2) {
        this.setState({ slidesToShowNumber: 2 })
      }
      if (result.data.length >= 3) {
        this.setState({ slidesToShowNumber: 3 })
      }
    })
  }

  getStandardProperties() {
    propertie.getPropertieStandardList().then((result) => {
      this.setState({ propertieStandardListe: result.data.slice(0, 6) })
    })
  }

  getPropertieType() {
    propertieType.getPropertie_typeList().then((result) => {
      this.setState({ propertieTypeListe: result.data })
    })
  }

  componentDidMount() {
    this.getPremiumProperties()
    this.getStandardProperties()
    this.getPropertieType()
  }

  render() {
    const {
      propertiePremiumListe,
      slidesToShowNumber,
      propertieStandardListe,
      propertieTypeListe,
    } = this.state

    return (
      <>
        <IndexHeader />
        {propertiePremiumListe.length > 0 && (
          <AnnonceKylimo
            propertieListe={propertiePremiumListe}
            slidesToShowNumber={slidesToShowNumber}
          />
        )}
        {propertiePremiumListe.length > 0 && <CallToAction />}
        <LastPublished propertieListe={propertieStandardListe} />
        <PropertiesLayout propertieTypeListe={propertieTypeListe} />
      </>
    )
  }
}

export default Dashboard
