import React, { Component } from 'react'
import Select from 'react-select'
// services call
import Categorie from '../../services/categorie'
import Propertie from '../../services/propertie'
import Propertie_type from '../../services/propertieType'
import villes from '../../services/villes'

const cipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2)
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code)

  return (text) =>
    text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
}

class Searchform extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: [],
      categorieListe: [],
      propertieTypeListe: [],
      zoneListe: [],
      villeCommuneListe: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangCategory = this.handleChangCategory.bind(this)
    this.handleChangePropertieType = this.handleChangePropertieType.bind(this)
    this.handleChangeZone = this.handleChangeZone.bind(this)
    this.handleChangeVilleCommune = this.handleChangeVilleCommune.bind(this)
  }

  // services function section
  getCategorieList() {
    Categorie.getCategorieList().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({
          categorieListe: [...this.state.categorieListe, newFormatData],
        })
      }
    })
  }
  getPropertieTypeList() {
    Propertie_type.getPropertie_typeList().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({
          propertieTypeListe: [...this.state.propertieTypeListe, newFormatData],
        })
      }
    })
  }
  getZoneList() {
    villes.getZone().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({ zoneListe: [...this.state.zoneListe, newFormatData] })
      }
    })
  }
  getVilleCommuneList(zoneId) {
    villes.getVillesCommuneByZoneId(zoneId).then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = {
          label: data[i].title,
          value: data[i].id,
          zoneId: data[i].zoneId,
        }
        this.setState({
          villeCommuneListe: [...this.state.villeCommuneListe, newFormatData],
        })
      }
    })
  }

  handleChange = (event) => {
    console.log(event)
    const target = event.target
    const value = target.value
    const name = target.name
    let item = { ...this.state.item }
    item[name] = value
    this.setState({ item })
  }

  handleChangCategory = (event) => {
    let item = { ...this.state.item }
    let array = [event.value]
    item['propertieProjetId'] = array
    this.setState({ item })
  }

  handleChangePropertieType = (event) => {
    console.log(event)
    let item = { ...this.state.item }
    let array = [event.value]
    item['propertieTypeId'] = array
    console.log(item)
    this.setState({ item })
  }

  handleChangeZone = (event) => {
    let item = { ...this.state.item }
    let array = [event.value]
    item['zoneId'] = array
    this.setState({ item })
    this.getVilleCommuneList(event.value)

    this.setState({
      villeCommuneListe: this.state.villeCommuneListe.filter(
        (item) => item.zoneId === event.value,
      ),
    })
  }

  handleChangeVilleCommune = (event) => {
    console.log(event)
    let item = { ...this.state.item }
    let array = [event.value]
    if (event) {
      item['villeCommuneId'] = array

      this.setState({ item })
    } else {
      item['villeCommuneId'] = ''
    }
  }

  SearchformEngine = (event) => {
    event.preventDefault()
    let item = { ...this.state.item }
    Propertie.PropertieSearchEngine(item).then((result) => {})

    // To create a cipher
    const myCipher = cipher('mySecretSalt')

    //Then cipher any text:
    myCipher(JSON.stringify(item)) // --> "7c606d287b6d6b7a6d7c287b7c7a61666f"

    window.location.href = '/advanced-search/' + myCipher(JSON.stringify(item))
  }

  componentDidMount() {
    this.getCategorieList()
    this.getPropertieTypeList()
    this.getZoneList()
    // this.getVilleCommuneList();
  }

  render() {
    const {
      categorieListe,
      propertieTypeListe,
      zoneListe,
      villeCommuneListe,
    } = this.state
    return (
      <div
        className="title-brand search_wrapper search_wr_2 with_search_on_end  with_search_form_float "
        id="search_wrapper"
      >
        <div id="search_wrapper_color"></div>
        <div className="adv-search-1  adv-search-2 " id="adv-search-2">
          <form className="visible-wrapper" onSubmit={this.SearchformEngine}>
            <div className="row">
              <div className="col-md-2">
                <Select
                  options={zoneListe}
                  placeholder="Zone"
                  onChange={this.handleChangeZone}
                />
              </div>
              <div className="col-md-3">
                <Select
                  options={villeCommuneListe}
                  placeholder="Ville ou commune"
                  isClearable
                  noOptionsMessage={() => 'Aucune donnée'}
                  // isClearable
                  // isMulti
                  onChange={this.handleChangeVilleCommune}
                />
              </div>
              <div className="col-md-2">
                <Select
                  options={categorieListe}
                  placeholder="Catégorie"
                  onChange={this.handleChangCategory}
                />
              </div>
              <div className="col-md-3">
                <Select
                  options={propertieTypeListe}
                  placeholder="Type"
                  onChange={this.handleChangePropertieType}
                />
              </div>
              <div className="col-md-2">
                <input
                  name="submit"
                  type="submit"
                  className="klmresidence_button"
                  id="advanced_submit_22"
                  value="Rechercher"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

Searchform.propTypes = {}

export default Searchform
