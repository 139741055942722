import React from "react";
import axios from "axios";

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from "../config";

export default {
  // Zone services
  createZone: (data) => {
    return axios.post(`${config.URL_END_POINT}/zone/add`, data);
  },
  getZone: () => {
    return axios.get(`${config.URL_END_POINT}/zone/list`);
  },
  updateZone: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/zone/update/${id}`, data);
  },

  deleteZone: (id) => {
    return axios.delete(`${config.URL_END_POINT}/zone/${id}`);
  },

  // Ville / commune services
  getVillesCommune: () => {
    return axios.get(`${config.URL_END_POINT}/ville_commune/list`);
  },
  getVillesCommuneByZoneId: (zoneId) => {
    return axios.get(
      `${config.URL_END_POINT}/ville_commune/zone/${zoneId}/list`
    );
  },

  createVillesCommune: (data) => {
    return axios.post(`${config.URL_END_POINT}/ville_commune/add`, data);
  },

  updateVillesCommune: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/ville_commune/update/${id}`,
      data
    );
  },

  deleteVillesCommune: (id) => {
    return axios.delete(`${config.URL_END_POINT}/ville_commune/${id}`);
  },
};
