import axios from 'axios'
import config from '../config'

export default {
  signUp: (data) => {
    return axios.post(`${config.URL_END_POINT}/auth/signup`, data)
  },
  signIn: (data) => {
    return axios.post(`${config.URL_END_POINT}/auth/signin`, data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    })
  },
  requestResetPassword: (data) => {
    return axios.post(`${config.URL_END_POINT}/auth/requestResetPassword`, data)
  },
  ResetPassword: (data) => {
    return axios.post(`${config.URL_END_POINT}/auth/resetPassword`, data)
  },
}
