import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'

// nodejs library that concatenates strings
import classnames from 'classnames'

// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Modal,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from 'reactstrap'

// services call

// loader import
import Loader01 from '../../loaders/loader_01'
import bgSection02 from '../../assets/img/bg/giulia-may-kHAeCxTmXDI-unsplash.jpg'

const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUsername = currentUser?.username
const currentUserRole = currentUser?.roles[0].title

class ExamplesNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUserMpspg: currentUser,
      admin: false,
      gestionnaire: false,
      navbarColor: '',
      navbarCollapse: false,
      item: [],
      ErrorMessage: '',
      loginError: '',
      Error: false,
      ErrorEmail: false,
      ErrorPwd: false,
      dataSending: false,
      loggedIn: false,
      type: 'password',
      modal01: false,
      modal02: false,
      modal03: false,
      modal04: false,
      ContainsUL: false,
      ContainsLL: false,
      ContainsN: false,
      ContainsSC: false,
      Contains8C: false,
      PasswordMatch: false,
      AllValid: false,
      countNotification: 0,
      countDette: 0,
      countStockLimite: 0,
      anonymousSubmission: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  toggleNavbarCollapse = () => {
    this.setState({
      navbarCollapse: !this.state.navbarCollapse,
    })
    document.documentElement.classList.toggle('nav-open')
  }

  updateNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      this.setState({
        navbarColor: '',
      })
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      this.setState({
        navbarColor: '',
      })
    }
  }

  toggleSignInModal = () => {
    this.setState({
      modal01: !this.state.modal01,
      modal02: false,
      modal03: false,
      loginError: '',
      Error: false,
    })
    localStorage.removeItem('submission')
  }

  toggleSignUpModal = () => {
    this.setState({
      modal02: !this.state.modal02,
      modal01: false,
      modal03: false,
      loginError: '',
      Error: false,
    })
    localStorage.removeItem('submission')
  }

  toggleResetPwdModal = () => {
    this.setState({
      modal03: !this.state.modal03,
      modal01: false,
      modal02: false,
      loginError: '',
      Error: false,
    })
    localStorage.removeItem('submission')
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    let item = { ...this.state.item }
    item[name] = value
    this.setState({ item })
  }

  showPassword = () => {
    this.setState({
      type: 'text',
    })
  }

  HidePassword = () => {
    this.setState({
      type: 'password',
    })
  }

  handleLogin = (event) => {
    event.preventDefault()
    localStorage.removeItem('submission')
    const { item } = this.state
    console.log('object save', item)

    this.setState({
      dataSending: true,
      Error: false,
    })

    axios
      .post(config.URL_END_POINT + '/auth/signin', item)
      .then((res) => {
        console.log(res.data)

        if (res.data.accessToken) {
          const customer = JSON.stringify(res.data.user)

          localStorage.setItem('token', res.data.accessToken)
          localStorage.setItem('userInfo', customer)

          // Increase expiration time after save
          localStorage.setItem('loggedIn', true)
          localStorage.setItem('iLg', true)
          localStorage.setItem('saved', new Date().getTime())

          setTimeout(() => {
            this.setState({
              loggedIn: true,
              dataSending: false,
              item: [],
              loginError: 'Connexion réussie...',
            })
          }, 2000)

          if (!localStorage.submissionData) {
            setTimeout(() => {
              if (res.data.user.roles[0].title === 'admin') {
                window.location.href = '/#/admin/dashboard'
              }
              if (res.data.user.roles[0].title === 'agent') {
                window.location.href = '/#/agent/dashboard'
              }
            }, 3000)
          }
        } else {
          this.setState({
            dataSending: false,
            Error: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)

        setTimeout(() => {
          this.setState({
            dataSending: false,
            Error: true,
            loginError: 'Email ou mot de passe incorrect',
          })
        }, 3000)
      })
  }

  handleSignUp = (event) => {
    event.preventDefault()
    localStorage.removeItem('submission')
    let item = { ...this.state.item }
    item['roles'] = ['agent']
    this.setState({ item })

    this.setState({
      dataSending: true,
      Error: false,
    })

    axios
      .post(config.URL_END_POINT + '/auth/signup', item)
      .then((res) => {
        console.log(res.data)

        if (res.data.accessToken) {
          const customer = JSON.stringify(res.data.user)

          localStorage.setItem('token', res.data.accessToken)
          localStorage.setItem('userInfo', customer)

          // Increase expiration time after save
          localStorage.setItem('loggedIn', true)
          localStorage.setItem('iLg', true)
          localStorage.setItem('saved', new Date().getTime())

          setTimeout(() => {
            this.setState({
              loggedIn: true,
              item: [],
              dataSending: false,
              loginError: 'Connexion réussie...',
            })
          }, 2000)

          if (!localStorage.submissionData) {
            setTimeout(() => {
              window.location.href = '/agent/dashboard'
            }, 3000)
          }
        } else {
          this.setState({
            dataSending: false,
            Error: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)

        setTimeout(() => {
          this.setState({
            dataSending: false,
            Error: true,
            loginError: 'Cet email est déjà utilisé!',
          })
        }, 3000)
      })
  }

  checkAnonymousSubmission = () => {
    if (localStorage.getItem('submission')) {
      this.setState({ modal01: true, anonymousSubmission: true })
    }
  }

  componentDidMount() {
    this.updateNavbarColor()

    if (this.state.currentUserMpspg) {
      this.setState({ currentUser: this.state.currentUserMpspg.user })
    }

    setInterval(() => {
      this.checkAnonymousSubmission()
    }, 2000)

    window.addEventListener('scroll', this.updateNavbarColor())

    window.removeEventListener('scroll', this.updateNavbarColor())
  }

  logOut() {
    localStorage.clear()
    window.location.href = '/'
  }

  render() {
    // const { loginError, Error, ErrorEmail, ErrorPwd, dataSending } = this.state;

    const {
      loggedIn,
      loginError,
      Error,
      modal01,
      modal02,
      modal03,
      anonymousSubmission,
    } = this.state

    return (
      <header>
        <Navbar
          className={classnames('bg-blue', this.state.navbarColor)}
          color-on-scroll="300"
          expand="lg"
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand
                data-placement="bottom"
                to="/"
                className="nav-link"
                title="Gombo"
                tag={Link}
              >
                {/* <img src={logo} /> */}
                Kylimo
              </NavbarBrand>
              <button
                aria-expanded={this.state.navbarCollapse}
                className={classnames('navbar-toggler navbar-toggler', {
                  toggled: this.state.navbarCollapse,
                })}
                onClick={this.toggleNavbarCollapse}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <Collapse
              className="justify-content-start"
              navbar
              isOpen={this.state.navbarCollapse}
            >
              <Nav navbar className="m-l-a">
                <NavItem>
                  <Link className="nav-link" to="/">
                    Accueil
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    to="/advanced-search/732a7267666d416c2a32533b55242a787a67786d7a7c616d587a67626d7c416c2a32533a55242a787a67786d7a7c616d5c71786d416c2a32533c5575"
                  >
                    Recherchez un bien
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/annonce-kylimo">
                    Annonces Kylimo
                  </Link>
                </NavItem>

                {!currentUser ? (
                  <NavItem
                    className="two-n-one btn btn-round btn-full-blue-violent"
                    onClick={this.toggleSignInModal}
                  >
                    <Link className="btn-round m-0" color="danger" to="#!">
                      <i className="nc-icon nc-circle-10"></i> Mon compte
                    </Link>
                  </NavItem>
                ) : (
                  <NavItem className="two-n-one btn btn-round btn-full-blue-violent">
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        color="default"
                        data-toggle="dropdown"
                        href="#account"
                        id="usermenubutton"
                        className="user-menu-button p-0"
                        nav
                        onClick={(e) => e.preventDefault()}
                        role="button"
                      >
                        <i className="nc-icon nc-circle-10"></i>{' '}
                        {currentUsername}
                      </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="dropdownMenuButton"
                        className="dropdown-info"
                      >
                        {currentUserRole === 'admin' && (
                          <DropdownItem href="/#/admin/dashboard">
                            Tableau de bord KYLIMO
                          </DropdownItem>
                        )}
                        <DropdownItem href="/#/agent/dashboard">
                          Mon tableau de bord
                        </DropdownItem>
                        <DropdownItem href="/#/agent/mon-profil">
                          Mon profil
                        </DropdownItem>
                        <DropdownItem href="/#/agent/annonces">
                          Mes annonces
                        </DropdownItem>
                        <DropdownItem href="/#/agent/annonce/add">
                          Publier une nouvelle annonces
                        </DropdownItem>
                        <DropdownItem href="#!" onClick={this.logOut}>
                          Se déconnecter
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </NavItem>
                )}
                {!currentUser && (
                  <NavItem className="two-n-one btn btn-round bg-yellow ">
                    <Link
                      to="/ajouter-une-propriete"
                      className="btn-round bg-yellow m-0"
                      color="danger"
                    >
                      Ajouter une annonce
                    </Link>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>

        <Modal isOpen={modal01} toggle={this.toggleSignInModal} size="lg">
          <div className="modal-body signin-modal">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={this.toggleSignInModal}
            >
              <i className="nc-icon nc-simple-remove"></i>
            </button>

            <div className="profile-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="profile-item">
                    <img src={bgSection02} alt="" />
                    <div className="profile-item-content">
                      {/* <h3>Entreprise</h3>
                      <p>Je recherche des freelances</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="profile--item">
                    <div className="profile-body">
                      <div className="mb-4 logo"></div>
                      <h3 className="mb-4">Connectez-vous à votre compte</h3>
                      {/* {this.state.Error && (
                  <div className="error text-red">
                    Les identifiants entrés sont incorrects
                    <p></p>
                  </div>
                )} */}
                      {anonymousSubmission && (
                        <p className="text-danger text-center">
                          Connectez-vous ou créez votre compte Kylimo pour
                          soumettre votre bien.
                        </p>
                      )}
                      {Error && (
                        <p className="text-danger text-center">{loginError}.</p>
                      )}
                      {loggedIn && (
                        <p className="text-success text-center">
                          {loginError}.
                        </p>
                      )}

                      <form onSubmit={this.handleLogin}>
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Entrez votre email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-4 password">
                          <input
                            type={this.state.type}
                            className="form-control"
                            placeholder="Entrez votre mot de passe"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                            required
                          />
                          {this.state.type === 'text' && (
                            <i
                              className="fa fa-eye-slash"
                              onClick={this.HidePassword}
                            ></i>
                          )}
                          {this.state.type === 'password' && (
                            <i
                              className="fa fa-eye"
                              onClick={this.showPassword}
                            ></i>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button className="btn btn-round btn-full-blue-violent full-width">
                              Connexion
                            </button>
                          )}
                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader01 />
                            </button>
                          )}
                        </div>
                      </form>
                      <p className="mb-0 text-muted register pull-left place-down">
                        <NavLink to="#!" onClick={this.toggleSignUpModal}>
                          Créer un compte
                        </NavLink>
                      </p>
                      <p className="mb-2 text-muted pull-right place-down">
                        <NavLink to="#!" onClick={this.toggleResetPwdModal}>
                          Mot de passe oublié ?
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={modal02} toggle={this.toggleSignUpModal} size="lg">
          <div className="modal-body signin-modal">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={this.toggleSignUpModal}
            >
              <i className="nc-icon nc-simple-remove"></i>
            </button>

            <div className="profile-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="profile-item">
                    <img src={bgSection02} alt="" />
                    <div className="profile-item-content">
                      {/* <h3>Entreprise</h3>
                      <p>Je recherche des freelances</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="profile--item">
                    <div className="profile-body">
                      <div className="mb-4 logo"></div>
                      <h3 className="mb-4">Créer un compte</h3>
                      {/* {this.state.Error && (
                  <div className="error text-red">
                    Les identifiants entrés sont incorrects
                    <p></p>
                  </div>
                )} */}
                      {Error && (
                        <p className="text-danger text-center">{loginError}.</p>
                      )}
                      {loggedIn && (
                        <p className="text-success text-center">
                          {loginError}.
                        </p>
                      )}

                      <form onSubmit={this.handleSignUp}>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Entrez votre nom"
                            name="last_name"
                            id="last_name"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Entrez votre prénom"
                            name="first_name"
                            id="first_name"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Entrez votre email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Entrez votre contact"
                            name="contact"
                            id="contact"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-4 password">
                          <input
                            type={this.state.type}
                            className="form-control"
                            placeholder="Entrez votre mot de passe"
                            name="password"
                            id="password"
                            onChange={this.handleChange}
                            required
                          />
                          {this.state.type === 'text' && (
                            <i
                              className="fa fa-eye-slash"
                              onClick={this.HidePassword}
                            ></i>
                          )}
                          {this.state.type === 'password' && (
                            <i
                              className="fa fa-eye"
                              onClick={this.showPassword}
                            ></i>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button className="btn btn-round btn-full-blue-violent full-width">
                              S'inscrire
                            </button>
                          )}
                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader01 />
                            </button>
                          )}
                        </div>
                      </form>
                      <p className="mb-0 text-muted register pull-left">
                        <NavLink to="#!" onClick={this.toggleSignInModal}>
                          Se connecter
                        </NavLink>
                      </p>
                      <p className="mb-2 text-muted pull-right">
                        <NavLink to="#!" onClick={this.toggleResetPwdModal}>
                          Mot de passe oublié ?
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={modal03} toggle={this.toggleResetPwdModal} size="lg">
          <div className="modal-body signin-modal">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={this.toggleResetPwdModal}
            >
              <i className="nc-icon nc-simple-remove"></i>
            </button>

            <div className="profile-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="profile-item">
                    <img src={bgSection02} alt="" />
                    <div className="profile-item-content">
                      {/* <h3>Entreprise</h3>
                      <p>Je recherche des freelances</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="profile--item">
                    <div className="profile-body">
                      <div className="mb-4 logo"></div>
                      <h3 className="mb-4">Réinitialiser mot de passe</h3>
                      {/* {this.state.Error && (
                  <div className="error text-red">
                    Les identifiants entrés sont incorrects
                    <p></p>
                  </div>
                )} */}
                      {Error && (
                        <p className="text-danger text-center">{loginError}.</p>
                      )}
                      {loggedIn && (
                        <p className="text-success text-center">
                          {loginError}.
                        </p>
                      )}

                      <form onSubmit={this.handleLogin}>
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Entrez votre email"
                            name="email"
                            id="email"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button className="btn btn-round btn-full-blue-violent full-width">
                              Réinitialiser mon mot de passe
                            </button>
                          )}
                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader01 />
                            </button>
                          )}
                        </div>
                      </form>
                      <p className="mb-0 text-muted register pull-left place-down">
                        <NavLink to="#!" onClick={this.toggleSignInModal}>
                          Se connecter
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </header>
    )
  }
}

export default ExamplesNavbar
