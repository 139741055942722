import React from "react";
import axios from "axios";

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from "../config";

export default {
  // Zone services
  createZone: (data) => {
    return axios.post(`${config.URL_END_POINT}/zone/add`, data);
  },
  getZoneList: () => {
    return axios.get(`${config.URL_END_POINT}/zone/list`);
  },
  updateZone: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/zone/update/${id}`, data);
  },

  deleteZone: (id) => {
    return axios.delete(`${config.URL_END_POINT}/zone/${id}`);
  },
};
