import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from 'reactstrap'
import sepMillier from '../../../../../components/function/millier'

const AnnonceItem = (props) => {
  return (
    <div className="row property_dash">
      <div className="blog_listing_image col-md-4">
        {/* <Link className="dashbard_unit_image" to="#!">
          {props.item?.propertie_images.length > 0 && (
            <img src={props.item?.propertie_images[0].link} />
          )}
        </Link> */}

        <div className="property_dashboard_location_klm">
          <Link
            className="dashbard_unit_title"
            to={'/annonce/' + props.item.link_prefix}
          >
            {props.item?.title != null ? props.item?.title : 'Inconnu'}
          </Link>

          <div className="property_dashboard_location">
            <Link to="http://kylimo:8888/city/bingerville/" rel="tag">
              {props.item?.ville_commune.title}
            </Link>
            ,{' '}
            <Link to="http://kylimo:8888/area/abidjan/" rel="tag">
              {props.item?.zone.title}
            </Link>{' '}
          </div>

          <div className="property_dashboard_status_unit">
            {props.item.expire_date && 'Expire le' + props.item.expire_date}
          </div>
        </div>
      </div>

      <div className="col-md-2 property_dashboard_types">
        <Link to="http://kylimo:8888/listings/vente/" rel="tag">
          {props.item?.propertie_projet.title}
        </Link>
        ,{' '}
        <Link to="http://kylimo:8888/action/immeuble/" rel="tag">
          {props.item?.propertie_type.title}
        </Link>{' '}
      </div>

      <div className="col-md-2 property_dashboard_status">
        {' '}
        <div className=" property_list_status_label publie">
          {props.item?.propertie_status.title}
        </div>
      </div>
      <div className="col-md-2 property_dashboard_price">
        {props.item?.sufixe_prix} <br></br> {sepMillier(props.item?.prix)} Fcfa{' '}
        {props.item?.prefixe_prix}
      </div>

      <div className="col-md-2 property_dashboard_action text-center">
        <div className="btn-group">
          <Nav className="order-actions-group flex-center">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                href="#account"
                id="dropdownMenuButton"
                className="order-settings-button p-0"
                nav
                onClick={(e) => e.preventDefault()}
                role="button"
              >
                <i className="nc-icon nc-settings-gear-65"></i>
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="dropdownMenuButton"
                className="dropdown-info"
              >
                <DropdownItem
                  href={'/admin/annonces/' + props.item?.id + '/update'}
                >
                  Modifier
                </DropdownItem>
                <DropdownItem
                  href="#!"
                  onClick={() => props.ablePropertie(props.item?.id)}
                >
                  Publier
                </DropdownItem>
                <DropdownItem
                  href="#!"
                  onClick={() => props.EditPropertieStatus(props.item?.id)}
                >
                  Désactiver
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
      </div>
    </div>
  )
}

export default AnnonceItem
