import React, { Component } from "react";
import Slider from "react-slick";
// import "../node_modules/slick-carousel/slick/slick.css";
// import "../node_modules/slick-carousel/slick/slick-theme.css";

import PropertieItem from "../../../../../components/properties/propertieItem";
// service import
import propertie from "../../../../../services/propertie";

class AnnonceKylimo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { propertieListe, slidesToShowNumber } = this.props;

    return (
      <div className="container">
        <div className="kylomo-section section-premium">
          <h2 className="section-title">Les annonces Kylimo</h2>
          {slidesToShowNumber >= 3 && (
            <Slider {...settings}>
              {propertieListe.map((item, index) => (
                <PropertieItem item={item} key={index} />
              ))}
            </Slider>
          )}
          <div className="section-standard">
            {slidesToShowNumber < 3 && (
              <div className="row">
                {propertieListe.map((item, index) => (
                  <div className="col-md-4">
                    <PropertieItem item={item} key={index} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AnnonceKylimo;
