import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Modal,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Container,
  Button,
} from 'reactstrap'
import classnames from 'classnames'
import auth from '../../../services/auth'

import logo from '../../../assets/img/logo.png'
import Loader_01 from '../../../loaders/loader_01'

// import image
import bgSection02 from '../../../assets/img/bg/eduardo-soares-GI5guEoMEgI-unsplash.jpg'

class signUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: '',
      currentUser: '',
      navbarColor: '',
      navbarCollapse: '',
      item: [],
      ErrorMessage: '',
      loginError: '',
      Error: false,
      ErrorEmail: false,
      ErrorPwd: false,
      rRPwd: false,
      dataSending: false,
      loggedIn: false,
      type: 'password',
      modal: false,
      modal02: false,
      modal03: false,
      modal04: false,
      ContainsUL: false,
      ContainsLL: false,
      ContainsN: false,
      ContainsSC: false,
      Contains8C: false,
      PasswordMatch: false,
      AllValid: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    console.log(this.props)
  }

  showPassword = () => {
    this.setState({
      type: 'text',
    })
  }

  HidePassword = () => {
    this.setState({
      type: 'password',
    })
  }

  handleChange = (event) => {
    this.setState({
      Error: false,
      ErrorEmail: false,
      ErrorPwd: false,
    })
    const target = event.target
    const value = target.value
    const password = target.value
    const name = target.name
    let item = { ...this.state.item }
    item[name] = value
    this.setState({ item })

    console.log('object', item)
  }

  handleLogin = (event) => {
    event.preventDefault()
    const { item } = this.state
    this.setState({
      dataSending: true,
    })

    auth
      .signIn(item)
      .then((res) => {
        console.log(res.data)

        if (res.data.accessToken) {
          const customer = JSON.stringify(res.data)

          localStorage.setItem('Mpspg', customer)

          // Increase expiration time after save
          localStorage.setItem('loggedIn', true)

          setTimeout(() => {
            this.setState({
              loggedIn: true,
              dataSending: false,
            })
          }, 4000)

          setTimeout(() => {
            // window.location.reload(false);
            window.location.href = '/home/dashboard'
            // this.props.history.push("/home/dashboard");
          }, 5000)
        } else {
          this.setState({
            dataSending: false,
            loginError: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        const errors = error.toJSON()

        if (errors.response.status == 401) {
          setTimeout(() => {
            this.setState({
              dataSending: false,
              Error: true,
              ErrorEmail: false,
              ErrorPwd: true,
              loginError: 'Mot de passe incorrect',
            })
          }, 4000)
        } else {
          setTimeout(() => {
            this.setState({
              dataSending: false,
              Error: true,
              ErrorEmail: true,
              ErrorPwd: true,
              loginError: 'Email ou mot de passe incorrect',
            })
          }, 4000)
        }
      })
  }

  togglePwdModal = () => {
    this.setState({
      modal03: !this.state.modal03,
      modal: false,
      Error: false,
      ErrorEmail: false,
      ErrorPwd: false,
    })
  }

  handleRequestResetPassword = (event) => {
    event.preventDefault()
    let item = { ...this.state.item }

    this.setState({ item })

    this.setState({
      dataSending: true,
    })

    auth
      .requestResetPassword(item)
      .then((res) => {
        console.log(res.data)

        setTimeout(() => {
          this.setState({
            dataSending: false,
            Error: false,
            ErrorEmail: false,
            rRPwd: true,
          })
        }, 3000)
      })
      .catch((error) => {
        console.log(error)

        if (error.response.status == 404) {
          setTimeout(() => {
            this.setState({
              dataSending: false,
              Error: true,
              ErrorEmail: true,
              loginError: 'Email incorrect',
            })
          }, 3000)
        }
      })
  }

  render() {
    const {
      type,
      loginError,
      Error,
      ErrorEmail,
      ErrorPwd,
      rRPwd,
      dataSending,
      Contains8C,
      ContainsUL,
      ContainsSC,
      modal,
      modal02,
      modal03,
      modal04,
    } = this.state

    return (
      <div className="bg-gray">
        <Navbar
          className="fixed-top navbar-transparent navbar navbar-expand-lg sign-in"
          color-on-scroll="300"
          expand="lg"
        >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand
                data-placement="bottom"
                to="/index"
                target="_blank"
                title="Gombo"
                tag={Link}
              >
                {/* <img src={logo} /> */}
                DSTOCK
              </NavbarBrand>
            </div>
          </Container>
        </Navbar>

        <div className="signup-card">
          <div className="signin-body">
            <h3 className="modal-title text-left" id="exampleModalLabel">
              Me connecter
            </h3>

            <div className="signin-box mt-5">
              {Error && (
                <p className="text-danger text-center">{loginError}.</p>
              )}
              <form onSubmit={this.handleLogin}>
                <div
                  className={
                    ErrorEmail
                      ? 'has-danger input-group mb-3'
                      : 'input-group mb-3'
                  }
                >
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Entrez votre email"
                    name="email"
                    id="email"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div
                  className={
                    ErrorEmail
                      ? 'has-danger input-group mb-4 password'
                      : ErrorPwd
                      ? 'has-danger input-group mb-4 password'
                      : 'input-group mb-4 password'
                  }
                >
                  <input
                    type={type}
                    className="form-control"
                    placeholder="Entrez votre mot de passe"
                    name="password"
                    id="password"
                    onChange={this.handleChange}
                    required
                  />
                  {type == 'text' && (
                    <i
                      className="fa fa-eye-slash"
                      onClick={this.HidePassword}
                    ></i>
                  )}
                  {type == 'password' && (
                    <i className="fa fa-eye" onClick={this.showPassword}></i>
                  )}
                </div>
                <div className="form-group text-left">
                  <Link
                    className="blue-link"
                    to="#!"
                    onClick={this.togglePwdModal}
                  >
                    J'ai perdu mon mot de passe
                  </Link>
                </div>
                <div className="form-group text-right">
                  {!dataSending && (
                    <button className="btn btn-round btn-full-blue-violent">
                      Se connexion
                    </button>
                  )}
                  {dataSending && (
                    <button
                      className="btn btn-round btn-full-blue-violent request-time"
                      disabled
                    >
                      Connexion
                      <Loader_01 />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          <div className="freelance-section">
            <img src={bgSection02} />
          </div>
        </div>

        <Modal isOpen={modal03} toggle={this.togglePwdModal}>
          <div className="modal-body">
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={this.togglePwdModal}
            >
              <i className="nc-icon nc-simple-remove"></i>
            </button>
            <h3 className="modal-title text-left" id="exampleModalLabel">
              Réinitialisation de votre mot de passe
            </h3>
            {!rRPwd && (
              <p>
                Entrez l'adresse e-mail associée à votre compte, et nous vous
                enverrons par e-mail un lien pour réinitialiser votre mot de
                passe.
              </p>
            )}

            {rRPwd && (
              <div>
                <p className="mt-5">
                  Vous recevrez un email de récupération de mot de passe d'ici
                  quelques minutes.
                </p>
                <div className="text-right">
                  <button
                    className="btn btn-round mt-2 btn-full-blue-violent"
                    onClick={this.togglePwdModal}
                  >
                    Revenir à la connexion
                  </button>
                </div>
              </div>
            )}

            <div className="signin-box mt-5">
              {Error && (
                <p className="text-danger text-center">{loginError}.</p>
              )}
              {!rRPwd && (
                <form onSubmit={this.handleRequestResetPassword}>
                  <div
                    className={
                      ErrorEmail
                        ? 'has-danger input-group mb-3'
                        : ErrorPwd
                        ? 'has-danger input-group mb-3'
                        : 'input-group mb-3'
                    }
                  >
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Entrez votre email"
                      name="email"
                      id="email"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group text-right">
                    {!dataSending && (
                      <button className="btn btn-round btn-full-blue-violent">
                        Réinitialiser mon mot de passe
                      </button>
                    )}
                    {dataSending && (
                      <button
                        className="btn btn-round btn-full-blue-violent request-time"
                        disabled
                      >
                        Réinitialisation en cours
                        <Loader_01 />
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default signUp
