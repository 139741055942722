import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
// import "../node_modules/slick-carousel/slick/slick.css";
// import "../node_modules/slick-carousel/slick/slick-theme.css";

class PropertiesLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { propertieTypeListe } = this.props

    // const annonceCount = (propertyTypeId) => {
    //   propertie.getPropertieByHisPropertyType(propertyTypeId).then((result) => {
    //     const annoncesCount = result.data.length;
    //     console.log("annonceCount", result.data.length);
    //     return annoncesCount;
    //   });
    // };

    return (
      <div className="kylomo-section section-property-type">
        <div className="container">
          <div>
            <h2 className="section-title">
              Parcourir <br></br>les annonces par catégorie
            </h2>
            {/* <Slider {...settings}>
            {propertieTypeListe.map((item, index) => (
              <PropertieItem />
            ))}
          </Slider> */}
            <div className="row">
              {propertieTypeListe.map((item, index) => (
                <div className="col-md-4 mb-5">
                  <div
                    className="property_listing places_listing"
                    style={{ backgroundImage: 'url(' + item.image + ')' }}
                  >
                    <h4>
                      <Link to={'annonce/type/' + item.title}>
                        {item.title}{' '}
                      </Link>
                    </h4>

                    <div className="property_location">
                      {item.properties.length} annonces
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertiesLayout
