import React, { Component } from 'react'
// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap'

// services importation
import Propertie from '../../../../services/propertie'
import avatar from '../../../../assets/img/sbcf-default-avatar.png'

// component importation
import NoticeArea from './Component/noticeArea'
import Body from './Component/body'

class PropertieSinglePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propertieData: [],
      propertieUser: [],
      propertieImages: [],
      propertie_type: [],
      propertie_projet: [],
      zone: [],
      ville_commune: [],
      activeIndex: 0,
      animating: false,
    }
  }

  getPropertieDetail(link) {
    Propertie.getPropertieByHisLinkPrefix(link).then((result) => {
      if (result.data.length == 0) {
        this.props.history.push('/404')
      } else {
        this.setState({
          propertieData: result.data[0],
          propertieImages: result.data[0].propertie_images,
          propertieUser: result.data[0].user,
          propertie_type: result.data[0].propertie_type,
          propertie_projet: result.data[0].propertie_projet,
          zone: result.data[0].zone,
          ville_commune: result.data[0].ville_commune,
        })
      }
    })
  }

  // carousel function
  onExiting = () => {
    this.setState({ animating: true })
  }
  onExited = () => {
    this.setState({ animating: false })
  }
  next = () => {
    if (this.state.animating) return
    const nextIndex =
      this.state.activeIndex === this.state.propertieData.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }
  previous = () => {
    if (this.state.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.propertieData.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }
  goToIndex = (newIndex) => {
    if (this.state.animating) return
    this.setState({ activeIndex: newIndex })
  }
  // eof

  componentDidMount() {
    this.getPropertieDetail(this.props.match.params.link)
  }

  render() {
    const {
      propertieData,
      propertieImages,
      propertieUser,
      propertie_type,
      propertie_projet,
      zone,
      ville_commune,
      activeIndex,
      animating,
    } = this.state
    return (
      <div className="single-propertie-page pt-5 bg--gray">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <NoticeArea
                propertieData={propertieData}
                propertieType={propertie_type}
                propertieProjet={propertie_projet}
                zone={zone}
                villeCommune={ville_commune}
              />
            </div>
            <div className="col-md-8">
              <div className="pt-o" id="carousel">
                <Container>
                  <Row>
                    <Col className="" md="12">
                      <Card className="page-carousel">
                        <Carousel
                          activeIndex={activeIndex}
                          next={this.next}
                          previous={this.previous}
                          interval={null}
                        >
                          <CarouselIndicators
                            items={propertieImages}
                            activeIndex={activeIndex}
                            onClickHandler={this.goToIndex}
                          />
                          {propertieImages.map((item) => {
                            return (
                              <CarouselItem
                                onExiting={this.onExiting}
                                onExited={this.onExited}
                                key={item.link}
                              >
                                <img src={item.link} alt={item.title} />
                              </CarouselItem>
                            )
                          })}
                          <a
                            className="left carousel-control carousel-control-prev"
                            data-slide="prev"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault()
                              this.previous()
                            }}
                            role="button"
                          >
                            <span className="fa fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </a>
                          <a
                            className="right carousel-control carousel-control-next"
                            data-slide="next"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault()
                              this.next()
                            }}
                            role="button"
                          >
                            <span className="fa fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </a>
                        </Carousel>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Body propertieData={propertieData} />
            </div>
            <div className="col-md-4">
              <div
                className="section-box property_user b-r-20"
                id="property_user_section"
              >
                <div className="panel-user-name-box">
                  <img src={avatar} alt="" />
                  <p className="panel-user-name">
                    {propertieUser.first_name + ' ' + propertieUser.last_name}
                  </p>
                </div>

                <div className="agent_contact_form">
                  <h4 className="show_contact">Contactez-moi</h4>
                  <div className="schedule_meeting">
                    Programmer un rendez-vous ?
                  </div>
                  <div className="signin-box mt-5">
                    <form onSubmit={this.handleLogin}>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez votre nom"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Entrez votre email"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Entrez votre téléphone"
                          name="email"
                          id="email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="input-group mb-3">
                        <textarea
                          name="message"
                          className="form-control"
                          onChange={this.handleChange}
                          value={
                            'Je suis intéressé par votre annonce ' +
                            '" ' +
                            propertieData.title +
                            ' "'
                          }
                          rows={8}
                          cols={8}
                        ></textarea>
                      </div>
                      <div className="form-group text-center">
                        <button className="btn btn-full-red">
                          Envoyer un email
                        </button>
                      </div>
                      <div className="form-group text-center">
                        <a
                          href={'tel:' + propertieUser.contact}
                          className="btn btn-full-red m-r-20"
                        >
                          <i className="fa fa-phone"></i> Appeler
                        </a>
                        <button className="btn btn-full-red">
                          <i className="fa fa-whatsapp"></i> WhatsApp
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PropertieSinglePage
