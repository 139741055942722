import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class CallToAction extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    return (
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action-text">
            <h2>Vous avez un bien immobilier que vous souhaitez vendre ?</h2>
            <p>Kylimo s’en charge pour vous</p>
          </div>
          <div className="call-to-action-button">
            <Link
              to="/annonce/kylimo/request"
              className="two-n-one btn btn-round bg-yellow nav-item"
            >
              Confier votre bien a kylimon
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default CallToAction
