import Dashboard from './views/pages/Agent_views/Dashboard/Dashboard'
import AllPropertie from './views/pages/Agent_views/Annonces/AllPropertie/AllPropertie'
import PublishPropertie from './views/pages/Agent_views/Annonces/PublishPropertie/PublishPropertie'
import UnPublishPropertie from './views/pages/Agent_views/Annonces/UnPublishPropertie/UnPublishPropertie'
import DisabledPropertie from './views/pages/Agent_views/Annonces/DisabledPropertie/DisabledPropertie'
import ExpiredPropertie from './views/pages/Agent_views/Annonces/ExpiredPropertie/ExpiredPropertie'
import Profil from './views/pages/Agent_views/Profil/Profil'
import AddAnnonce from './views/pages/Agent_views/Annonces/AddAnnonce/AddAnnonce'
import UpdateAnnonce from './views/pages/Agent_views/Annonces/UpdateAnnonce/UpdateAnnonce'

const Routes = [
  {
    path: '/agent/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/agent/annonces/publish',
    exact: true,
    name: 'PublishPropertie',
    component: PublishPropertie,
  },
  {
    path: '/agent/annonces/unpublish',
    exact: true,
    name: 'UnPublishPropertie',
    component: UnPublishPropertie,
  },
  {
    path: '/agent/annonces/disabled',
    exact: true,
    name: 'DisabledPropertie',
    component: DisabledPropertie,
  },
  {
    path: '/agent/annonces/expired',
    exact: true,
    name: 'ExpiredPropertie',
    component: ExpiredPropertie,
  },
  {
    path: '/agent/annonces',
    exact: true,
    name: 'AllPropertie',
    component: AllPropertie,
  },
  {
    path: '/agent/annonce/add',
    exact: true,
    name: 'AddAnnonce',
    component: AddAnnonce,
  },
  {
    path: '/agent/annonce/:propertieId/update',
    exact: true,
    name: 'UpdateAnnonce',
    component: UpdateAnnonce,
  },
  {
    path: '/agent/mon-profil',
    exact: true,
    name: 'Profil',
    component: Profil,
  },
]

export default Routes
