import React, { Component } from 'react'
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Loader_01 from '../../../../../loaders/loader_01'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { DropzoneArea } from 'material-ui-dropzone'

// function called
import titleTransformer from '../../../../../components/function/titleTransformer'
// services call
import Propertie from '../../../../../services/propertie'
import Categorie from '../../../../../services/categorie'
import Propertie_type from '../../../../../services/propertieType'
import Propertie_image from '../../../../../services/propertieImage'
import villes from '../../../../../services/villes'

const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUsername = currentUser?.username
class AddAnnonce extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: [],
      categorieListe: [],
      propertieTypeListe: [],
      zoneListe: [],
      villeCommuneListe: [],
      files: [],
      filelist: [],
      dataSending: false,
      categoryIdValidator: 0,
      propertieTypeIdValidator: 0,
      zoneIdValidator: 0,
      villeCommuneIdValidator: 0,
      inputValidator: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.onEditorChange = this.onEditorChange.bind(this)
    this.handleChangCcategory = this.handleChangCcategory.bind(this)
    this.handleChangePropertieType = this.handleChangePropertieType.bind(this)
    this.handleChangeZone = this.handleChangeZone.bind(this)
    this.handleChangeVilleCommune = this.handleChangeVilleCommune.bind(this)
    this.handleChangeFiles = this.handleChangeFiles.bind(this)
  }

  handleChange = (event) => {
    console.log(event)
    const target = event.target
    const value = target.value
    const name = target.name
    let item = { ...this.state.item }
    item[name] = value
    this.setState({ item })
  }

  onEditorChange = (event, editor) => {
    const name = 'body'
    const value = editor.getData()
    let item = { ...this.state.item }
    item[name] = value
    this.setState({ item })
  }

  handleChangCcategory = (event) => {
    let item = { ...this.state.item }
    item['propertieProjetId'] = event.value
    this.setState({ item })
  }

  handleChangePropertieType = (event) => {
    let item = { ...this.state.item }
    item['propertieTypeId'] = event.value
    this.setState({ item })
  }

  handleChangeZone = (event) => {
    let item = { ...this.state.item }
    item['zoneId'] = event.value
    this.setState({ item })
  }

  handleChangeVilleCommune = (event) => {
    let item = { ...this.state.item }
    item['villeCommuneId'] = event.value
    this.setState({ item })
  }

  handleChangeFiles(files) {
    console.log('object files', files)
    this.setState({
      files: files,
    })
  }

  // services function section
  createPropertie() {
    const currentUser = JSON.parse(localStorage.getItem('userInfo'))
    const currentUserId = currentUser.id
    let item = { ...this.state.item }
    item['userId'] = currentUserId
    this.setState({ item })
    Propertie.createPropertie(item).then((result) => {
      setTimeout(() => {
        localStorage.removeItem('submissionData')
        window.location.href = '/'
      }, 2000)
    })
  }
  getCategorieList() {
    Categorie.getCategorieList().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({
          categorieListe: [...this.state.categorieListe, newFormatData],
        })
      }
    })
  }
  getPropertieTypeList() {
    Propertie_type.getPropertie_typeList().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({
          propertieTypeListe: [...this.state.propertieTypeListe, newFormatData],
        })
      }
    })
  }
  getZoneList() {
    villes.getZone().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({ zoneListe: [...this.state.zoneListe, newFormatData] })
      }
    })
  }
  getVilleCommuneList() {
    villes.getVillesCommune().then((result) => {
      const data = result.data
      for (let i = 0; i < data.length; i++) {
        let newFormatData = { label: data[i].title, value: data[i].id }
        this.setState({
          villeCommuneListe: [...this.state.villeCommuneListe, newFormatData],
        })
      }
    })
  }

  handleSubmission = (event) => {
    event.preventDefault()
    this.setState({ dataSending: true })

    const currentUser = JSON.parse(localStorage.getItem('userInfo'))
    const currentUserId = currentUser.id
    let item = { ...this.state.item }
    item['propertiePrivilegeId'] = 1
    item['propertieStatusId'] = 1
    item['link_prefix'] = titleTransformer(item.title)
    item['userId'] = currentUserId
    this.setState({ item })

    Propertie.createPropertie(item).then((result) => {
      const formData = new FormData()
      // formData.append("files", this.state.files);
      // Read selected files
      for (let i = 0; i < this.state.files.length; i++) {
        const isformat = formData.append('files', this.state.files[i])
        this.setState({
          filelist: [...this.state.filelist, isformat],
        })
      }
      formData.append('propertyId', result.data.data.id)

      Propertie_image.createPropertie_image(formData).then((result) => {
        setTimeout(() => {
          this.setState({ dataSending: false })
          window.location.href = '/agent/annonces/unpublish'
        }, 3000)
      })
    })
  }

  componentDidMount() {
    this.getCategorieList()
    this.getPropertieTypeList()
    this.getZoneList()
    this.getVilleCommuneList()
    if (!this.state.inputValidator) {
      setInterval(() => {
        if (
          this.state.item.propertieProjetId &&
          this.state.item.propertieTypeId &&
          this.state.item.villeCommuneId &&
          this.state.item.zoneId
        ) {
          this.setState({ inputValidator: true })
        }
      }, 1000)
    }
  }

  render() {
    const {
      item,
      categorieListe,
      propertieTypeListe,
      zoneListe,
      villeCommuneListe,
      inputValidator,
    } = this.state
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Mon profil</h2>
        </div>
        <div className="dsh-content">
          <div
            className="alert-with-icon alert alert-warning fade show"
            role="alert"
          >
            <div className="container">
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-bell-55"></i> Après soumission de
                  votre propriété, celle ci est soumise à une validation dans
                  les 48h avant d'etre publiée
                </div>
              </div>
            </div>
          </div>
          <div className="card userinfo p-5 b-r-20">
            {' '}
            <div className="profile--item">
              <div className="profile-body">
                <form onSubmit={this.handleSubmission}>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <p>
                        * Ces champs sont obligatoires : Titre,Type d'annonce,
                        Type de bien, Zone, Ville / commmune, Prix, Médias de
                        propriété
                      </p>
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Description du bien</h2>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>* Titre (obligatoire)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="title"
                          id="title"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>* Type d'annonce</label>
                      <Select
                        options={categorieListe}
                        name="propertieProjetId"
                        id="propertieProjetId"
                        onChange={this.handleChangCcategory}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label>* Type de bien</label>
                      <Select
                        options={propertieTypeListe}
                        name="propertieTypeId"
                        id="propertieTypeId"
                        onChange={this.handleChangePropertieType}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-12 mb-4">
                      <label>Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={this.state.item.body}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor)
                        }}
                        onChange={this.onEditorChange}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor)
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor)
                        }}
                      />
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Localisation</h2>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>* Zone</label>
                      <Select
                        options={zoneListe}
                        name="zoneId"
                        id="zoneId"
                        onChange={this.handleChangeZone}
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>* Ville / commmune</label>
                      <Select
                        options={villeCommuneListe}
                        name="villeCommuneId"
                        id="villeCommuneId"
                        onChange={this.handleChangeVilleCommune}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Tarif</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>* Prix en Fcfa (chiffre seulement)</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder=""
                          name="prix"
                          id="prix"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    {item.propertieProjetId == 2 && (
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label>Après le prix (ex: "/mois")</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="prefixe_prix"
                            id="prefixe_prix"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label>
                          Avant le prix (ex: "à partir de ") optionnel
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="sufixe_prix"
                          id="sufixe_prix"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <h2>Médias</h2>
                    </div>
                    <div className="col-md-12 mb-4">
                      <label>Photos</label>
                      <DropzoneArea
                        acceptedFiles={['image/*', 'video/*', 'application/*']}
                        onChange={this.handleChangeFiles}
                        showFileNames
                        dropzoneText="Glissez et déposez vos images ici"
                        showAlerts={false}
                        filesLimit={20}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-4">
                        {!inputValidator && !this.state.dataSending && (
                          <button
                            className="btn btn-round btn-full-blue-violent full-width"
                            disabled
                          >
                            Soumettre la propriété
                          </button>
                        )}
                        {inputValidator && !this.state.dataSending && (
                          <button className="btn btn-round btn-full-blue-violent full-width">
                            Soumettre la propriété
                          </button>
                        )}

                        {this.state.dataSending && (
                          <button
                            className="btn btn-round btn-full-blue-violent full-width"
                            disabled
                          >
                            <Loader_01 />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AddAnnonce
