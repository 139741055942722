import axios from 'axios'

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from '../config'

export default {
  // Propertie_type services
  createPropertie_type: (data) => {
    return axios.post(`${config.URL_END_POINT}/propertie_type/add`, data)
  },
  getPropertie_typeList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie_type/list`)
  },
  updatePropertie_type: (id, data) => {
    return axios.put(
      `${config.URL_END_POINT}/propertie_type/update/${id}`,
      data,
    )
  },

  deletePropertie_type: (id) => {
    return axios.delete(`${config.URL_END_POINT}/propertie_type/${id}`)
  },
}
