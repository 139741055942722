import axios from 'axios'

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from '../config'

export default {
  // Propertie services

  // global services
  createPropertie: (data) => {
    return axios.post(`${config.URL_END_POINT}/propertie/add`, data)
  },
  PropertieSearchEngine: (data) => {
    return axios.post(`${config.URL_END_POINT}/propertie/search`, data)
  },
  getPropertieList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie/list`)
  },
  getPropertieByHisId: (id) => {
    return axios.get(`${config.URL_END_POINT}/propertie/${id}`)
  },
  getPropertieByHisLinkPrefix: (id) => {
    return axios.get(`${config.URL_END_POINT}/propertie/link/${id}`)
  },
  getPropertieByHisPropertyType: (id) => {
    return axios.get(`${config.URL_END_POINT}/propertie/propertyType/${id}`)
  },
  getPropertiePremiumList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie/premium/list`)
  },
  getPropertieStandardList: () => {
    return axios.get(`${config.URL_END_POINT}/propertie/standard/list`)
  },
  updatePropertie: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/propertie/update/${id}`, data)
  },

  deletePropertie: (id) => {
    return axios.delete(`${config.URL_END_POINT}/propertie/${id}`)
  },
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // connected user services
  getAllUserPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/list`)
  },
  getUserPublishPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/publish/list`)
  },
  getUserUnpublishPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/not-publish/list`)
  },
  getUserExpiredPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/expired/list`)
  },
  getPublishedUserPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/publish/list`)
  },
  getUnpubishUserPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/pending/list`)
  },
  getDesabledUserPropertieList: (id) => {
    return axios.get(`${config.URL_END_POINT}/${id}/propertie/not-publish/list`)
  },
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // ***********************************************************************
  // admin services
  getPublishedPropertieListForAdmin: (adminId) => {
    return axios.get(
      `${config.URL_END_POINT}/admin/${adminId}/propertie/publish/list`,
    )
  },
  getPendingPropertieListForAdmin: (adminId) => {
    return axios.get(
      `${config.URL_END_POINT}/admin/${adminId}/propertie/pending/list`,
    )
  },
  getPropertiePremiumListForAdmin: (adminId) => {
    return axios.get(
      `${config.URL_END_POINT}/admin/${adminId}/propertie/premium/list`,
    )
  },
  getPropertieStandardListForAdmin: (adminId) => {
    return axios.get(
      `${config.URL_END_POINT}/admin/${adminId}/propertie/standard/list`,
    )
  },
}
