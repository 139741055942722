import axios from 'axios'

//   url = 'https://nymba-back-end-new.eu-gb.mybluemix.net';
import config from '../config'

export default {
  // Ville_commune services
  createVille_commune: (data) => {
    return axios.post(`${config.URL_END_POINT}/ville_commune/add`, data)
  },
  getVille_communeList: () => {
    return axios.get(`${config.URL_END_POINT}/ville_commune/list`)
  },
  updateVille_commune: (id, data) => {
    return axios.put(`${config.URL_END_POINT}/ville_commune/update/${id}`, data)
  },

  deleteVille_commune: (id) => {
    return axios.delete(`${config.URL_END_POINT}/ville_commune/${id}`)
  },
}
