import React, { Component } from 'react'
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Nav,
} from 'reactstrap'
import Loader_01 from '../../../../loaders/loader_01'

import Ville_commune from '../../../../services/commune'
import Zone from '../../../../services/zone'

class FieldVille_commune extends Component {
  constructor(props) {
    super(props)
    this.state = {
      communeListe: [],
      zoneListe: [],
      item: [],
      dataSending: false,
    }
  }

  createVille_commune() {
    this.setState({ dataSending: true })
    let item = { ...this.state.item }
    this.setState({ item })
    Ville_commune.createVille_commune(item).then(() => {
      this.setState({ dataSending: false })
      this.componentDidMount()
    })
  }

  getVille_communeList() {
    Ville_commune.getVille_communeList().then((result) => {
      const data = result.data
      this.setState({
        communeListe: data,
      })
    })
  }

  getZoneList() {
    Zone.getZoneList().then((result) => {
      const data = result.data
      this.setState({
        zoneListe: data,
      })
    })
  }

  componentDidMount() {
    this.getVille_communeList()
    this.getZoneList()
  }

  render() {
    const { communeListe, zoneListe } = this.state
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Communes</h2>
        </div>
        <div className="dsh-content">
          {/* <div
            className="alert-with-icon alert alert-warning fade show"
            role="alert"
          >
            <div className="container">
              <div className="alert-wrapper">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="nc-icon nc-simple-remove"></i>
                </button>
                <div className="message">
                  <i className="nc-icon nc-bell-55"></i> Après soumission de votre
                  propriété, celle ci est soumise à une validation dans les 48h
                  avant d'etre publiée
                </div>
              </div>
            </div>
          </div>
          */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <p>* Ces champs sont obligatoires</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="card__o userinfo p-3 b-r-10">
                <form onSubmit={this.createVille_commune}>
                  <div className="profile--item">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>* Titre (obligatoire)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="title"
                            id="title"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>* Zone (obligatoire)</label>
                          <select
                            className="form-control"
                            name="zoneId"
                            id="zoneId"
                            onChange={this.handleChange}
                            required
                          >
                            <option></option>
                            {zoneListe.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-group mb-3">
                          <label>Description</label>
                          <textarea
                            cols="120"
                            rows="5"
                            className="form-control"
                            placeholder=""
                            name="description"
                            id="description"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group mb-4">
                          {!this.state.dataSending && (
                            <button className="btn btn-round btn-full-blue-violent full-width">
                              Enregistrer
                            </button>
                          )}

                          {this.state.dataSending && (
                            <button
                              className="btn btn-round btn-full-blue-violent full-width"
                              disabled
                            >
                              <Loader_01 />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card__o userinfo p-3 b-r-10">
                <div className="list--item">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>Nom</th>
                            <th>Zone</th>
                            <th>Description</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {communeListe.map((item, index) => (
                            <tr key={index}>
                              <td> {item.id} </td>
                              <td> {item.title} </td>
                              <td> {item.zone.title} </td>
                              <td> {item.description} </td>
                              <td>
                                <Nav className="order-actions-group flex-center">
                                  <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle
                                      aria-expanded={false}
                                      aria-haspopup={true}
                                      color="default"
                                      data-toggle="dropdown"
                                      href="#account"
                                      id="dropdownMenuButton"
                                      className="order-settings-button p-0"
                                      nav
                                      onClick={(e) => e.preventDefault()}
                                      role="button"
                                    >
                                      <i className="nc-icon nc-settings-gear-65"></i>
                                    </DropdownToggle>
                                    <DropdownMenu
                                      aria-labelledby="dropdownMenuButton"
                                      className="dropdown-info"
                                    >
                                      <button className="dropdown-item">
                                        Modifier
                                      </button>
                                    </DropdownMenu>
                                    <DropdownMenu
                                      aria-labelledby="dropdownMenuButton"
                                      className="dropdown-info"
                                    >
                                      <button className="dropdown-item">
                                        Supprimer
                                      </button>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </Nav>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FieldVille_commune
