import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Routes from '../../../../routes'
import Dashboard from './Dashboard'
// core components
import Navbar from '../../../../components/Navbars/ExamplesNavbar.js'
import DemoFooter from '../../../../components/Footers/DemoFooter'

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  cleanup() {
    document.body.classList.remove('profile-page')
  }

  componentDidMount() {
    document.documentElement.classList.remove('nav-open')
    document.body.classList.add('profile-page')
    this.cleanup()
  }

  render() {
    const menu = Routes.map((route, index) => {
      console.log(route)
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null
    })

    return (
      <>
        <Navbar />
        <div className="main">
          <Switch>
            {menu}
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </div>
        <DemoFooter />
      </>
    )
  }
}

export default LandingPage
