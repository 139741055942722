import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// ProtectedRoute
import ProtectedRoute from './views/Authentication/ProtectedRoute'

// styles
// import "bootstrap/scss/bootstrap.scss";
import 'assets/css/paper-kit.css?v=1.3.0'
import 'assets/demo/demo.css?v=1.3.0'
import 'assets/css/style-gombo.css'
// pages
// import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
import Home from './views/pages/all_views/home/home'
import UserDashboard from './views/pages/Agent_views/Agent_views'
import AdminDashboard from './views/pages/Admin_views/Admim_views'
// Choose profil type import
import NotFound from 'views/pages/all_views/NotFound'
import SignUp from './views/pages/SignUp/SignUp'
import AnnonceKylimoForm from './views/pages/all_views/AnnonceKylimoForm/AnnonceKylimoForm'
// import Customer from "views/pages/SignUp/customer";
// others

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <BrowserRouter basename="/#/">
          <Switch>
            <ProtectedRoute path="/agent" component={UserDashboard} />
            <Route
              path="/404"
              exact
              render={(props) => <NotFound {...props} />}
            />
            <ProtectedRoute path="/admin" component={AdminDashboard} />
            <Route
              path="/auth/signin"
              exact
              render={(props) => <SignUp {...props} />}
            />
            <Route
              path="/annonce/kylimo/request"
              exact
              render={(props) => <AnnonceKylimoForm {...props} />}
            />
            {/* <ProtectedRoute path="/" component={Home} /> */}
            <Route path="/" render={(props) => <Home {...props} />} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </>
    )
  }
}

export default App
