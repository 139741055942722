import React, { Component } from 'react'
import PropTypes from 'prop-types'

class annonceResume extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    const {
      PublishPropertieCount,
      PendindPropertieCount,
      UserListCount,
    } = this.props
    return (
      <div className="annonces-recap">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="card-stats card b-r-20">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 col-md-4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-copy-04 text-success"></i>
                    </div>
                  </div>
                  <div className="col-7 col-md-8">
                    <div className="numbers text-right">
                      <p className="card-category">Annonces publiées</p>
                      <p className="card-title"> {PublishPropertieCount} </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <hr></hr>
                <div className="stats">
                  Voir la liste <i className="nc-icon nc-minimal-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="card-stats card b-r-20">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 col-md-4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-copy-04 text-danger"></i>
                    </div>
                  </div>
                  <div className="col-7 col-md-8">
                    <div className="numbers text-right">
                      <p className="card-category">En attente d'approbation</p>
                      <p className="card-title"> {PendindPropertieCount} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <hr></hr>
                <div className="stats">
                  Voir la liste <i className="nc-icon nc-minimal-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-4">
            <div className="card-stats card b-r-20">
              <div className="card-body">
                <div className="row">
                  <div className="col-5 col-md-4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-single-02 text-warning"></i>
                    </div>
                  </div>
                  <div className="col-7 col-md-8">
                    <div className="numbers text-right">
                      <p className="card-category">Utilisateurs</p>
                      <p className="card-title"> {UserListCount} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <hr></hr>
                <div className="stats">
                  Voir la liste <i className="nc-icon nc-minimal-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

annonceResume.propTypes = {}

export default annonceResume
