import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AnnonceResume from './Component/annonceResume'
import AnnonceItem from './Component/AnnonceItem'

// services import

import Propertie from '../../../../services/propertie'
import Account from '../../../../services/account'

const currentUser = JSON.parse(localStorage.getItem('userInfo'))
const currentUserId = currentUser?.id
const currentUsername = currentUser?.username
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      propertieListe: [],
      PublishPropertieCount: 0,
      PendindPropertieCount: 0,
      UserListCount: 0,
    }
  }

  // count functions
  // *********************************************************
  // *********************************************************
  // *********************************************************

  geActivedProperties() {
    Propertie.getPublishedPropertieListForAdmin(currentUserId).then(
      (result) => {
        this.setState({
          propertieListe: result.data,
          PublishPropertieCount: result.data.length,
        })
      },
    )
  }

  getPendingProperties() {
    Propertie.getPendingPropertieListForAdmin(currentUserId).then((result) => {
      this.setState({
        PendindPropertieCount: result.data.length,
      })
    })
  }

  getAllUserList() {
    Account.getUserList(currentUserId).then((result) => {
      this.setState({
        UserListCount: result.data.length,
      })
    })
  }
  // *********************************************************
  // *********************************************************
  // *********************************************************

  getActivedProperties() {
    Propertie.getPublishedPropertieListForAdmin().then((result) => {
      this.setState({ propertieListe: result.data })
    })
  }

  EditPropertieStatus = (propertieId, statusId) => {
    let data = {
      propertieStatusId: statusId,
    }

    Propertie.updatePropertie(propertieId, data).then(() =>
      this.componentDidMount(),
    )
  }

  componentDidMount() {
    this.getActivedProperties()
    this.geActivedProperties()
    this.getPendingProperties()
    this.getAllUserList()
  }

  render() {
    const {
      propertieListe,
      PublishPropertieCount,
      PendindPropertieCount,
      UserListCount,
    } = this.state
    return (
      <div className="dashboard-content">
        <div className="dsh-page-title">
          <h5>Bienvenue</h5>
          <h2>Tableau de bord</h2>
        </div>
        <div className="dsh-content">
          <AnnonceResume
            PublishPropertieCount={PublishPropertieCount}
            PendindPropertieCount={PendindPropertieCount}
            UserListCount={UserListCount}
          />
          <div className="card user-active-properties-box">
            <h2 className="mb-4">
              <strong>Propriétés publiée ({propertieListe.length}) </strong>{' '}
            </h2>
            <div className="dashboard_table_list_header mb-3 row">
              <div className="col-md-4">Propriété</div>
              <div className="col-md-2">Catégorie</div>
              <div className="col-md-2">Statut</div>
              <div className="col-md-2">Prix </div>
              <div className="col-md-2">Actions</div>
            </div>
            {propertieListe.map((item, index) => (
              <AnnonceItem
                key={index}
                item={item}
                EditPropertieStatus={this.EditPropertieStatus}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Dashboard
